import * as React from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { WhatsappJoinDialog } from "./WhatsappJoinDialog";

import { config } from "../env/config";
import { getUserKey, postData } from "../common/utils";
import image_logo from "../assets/site/logo.png";
import { Loader } from "../common/loader/Loader";
import { SpecialDhPlans } from "./SpecialDhPlans";

export const EnrollNowButton = ({ event, refreshEvents }) => {
    const [showLoader, setShowLoader] = React.useState(false);

    const [enrollmentResponse, setEnrollmentResponse] = React.useState(null);

    const userData = getUserKey();

    const hideWhatsappDialog = () => {
        setEnrollmentResponse(null);
        refreshEvents();
    };

    const onEnrollEventClickHandler = async () => {
        const payload = {
            booked_by: userData.name,
            country_id: userData.country_id,
            email: userData.email,
            event: {
                booking_enddate: event.booking_enddate,
                booking_endtime: event.booking_endtime,
                event_amount: event.event_amount,
                event_for: event.event_for,
                eventname: event.eventname,
                id: event.id,
                participant: event.participant,
                //     btnStatus: event.btnStatus,
                //     eventCancelReason: event.eventCancelReason,
                //     eventStatus: event.eventStatus,
                //     event_time: event.event_time,
                //     event_type: event.event_type,
                //     eventdate: event.eventdate,
                //     isCancel: event.isCancel,
            },
            member_id: userData.member_id,
            state_id: userData.state_id,
            user_id: userData.id,
        };

        const res = await postData({
            url: `${config.serverUrl}/eventFreeBooking.php`,
            payload,
            highlightError: true,
        });

        setShowLoader(false);

        if (res) {
            toast.success("Enrollment Successful!");

            if (res.whatsapplink) {
                setEnrollmentResponse(res);
            } else {
                hideWhatsappDialog();
            }
        }
    };

    return (
        <>
            <Button
                variant="contained"
                size="small"
                onClick={onEnrollEventClickHandler}
                sx={{
                    width: { xs: "100%", md: "220px" },
                    height: "40px",
                    background: "#9045BF",
                    display: `${event.isCancel === "1" ? "none" : "flex"}`,
                }}
            >
                Enroll Now
                <Loader show={showLoader} />
            </Button>

            {/* Show join whatsapp button when update response contains whatsappLink */}
            {enrollmentResponse?.whatsapplink ? (
                <WhatsappJoinDialog
                    show={enrollmentResponse.whatsapplink}
                    primaryMsg={enrollmentResponse.first}
                    secondaryMsg={enrollmentResponse.second}
                    whatsappLink={enrollmentResponse.whatsapplink}
                    onCloseHandler={hideWhatsappDialog}
                />
            ) : null}
        </>
    );
};
