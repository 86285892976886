import React, { Suspense } from "react";
import { css } from "@emotion/css";
import enLocale from "date-fns/locale/en-US";
import CssBaseline from "@mui/material/CssBaseline";
import {
    HashRouter as Router,
    Routes,
    Route,
    Redirect,
    useNavigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./login/Login";

import "./App.css";
import "./common/yup-utils";
import Registration from "./registration/Registration";
import Page from "./common/page/Page";
import Dashboard from "./dashboard/Dashboard";
import Courses from "./courses/Courses";
import Shreekalp from "./shreekalp/Shreekalp";
import More from "./more/More";
import Info from "./info/Info";
import { getUserKey } from "./common/utils";
import { Box, CircularProgress } from "@mui/material";
import LandingPage from "./landing/Landing";
import PublicShreekalpInfo from "./landing/public-shreekalp-info/PublicShreekalpInfo";
import PublicAboutUs from "./landing/public-about-us/PublicAboutUs";
import PublicEvents from "./landing/public-events/PublicEvents";

const MeetingClient = React.lazy(() => import("././meeting/MeetingClient"));

const appStyle = css({
    height: "100%",
});

const theme = createTheme({
    typography: {
        fontFamily: "Open Sans, Medium",
    },
});

const ProtectedComponent = ({ isAuthenticated, component }) => {
    const userData = getUserKey();
    const navigate = useNavigate();

    if (!userData) {
        // Use navigate to redirect to the login page if not authenticated
        navigate("/login");
    }

    return component;
};

function App() {
    const userData = getUserKey();

    return (
        <div className={appStyle}>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={enLocale}
                >
                    <Router>
                        <Routes>
                            <Route
                                path="/public/aboutus"
                                element={<PublicAboutUs />}
                            />
                            <Route
                                path="/public/events"
                                element={<PublicEvents />}
                            />
                            <Route
                                path="/public/shreekalp"
                                element={<PublicShreekalpInfo />}
                            />
                            <Route path="/login" element={<Login />} />
                            <Route
                                path="/registration"
                                element={<Registration />}
                            />

                            <Route
                                path="/"
                                exact
                                element={
                                    userData ? (
                                        <Page>
                                            <Dashboard />
                                        </Page>
                                    ) : (
                                        <LandingPage />
                                    )
                                }
                            />

                            <Route
                                path="/dashboard"
                                element=<ProtectedComponent
                                    component={
                                        <Page>
                                            <Dashboard />
                                        </Page>
                                    }
                                />
                            />

                            <Route
                                path="/meeting"
                                element=<ProtectedComponent
                                    component={
                                        <Suspense fallback={LoadingScreen}>
                                            <MeetingClient />
                                        </Suspense>
                                    }
                                />
                            />
                            <Route
                                path="/courses"
                                element=<ProtectedComponent
                                    component={
                                        <Page title="Courses">
                                            <Courses />
                                        </Page>
                                    }
                                />
                            />
                            <Route
                                path="/shreekalp"
                                element=<ProtectedComponent
                                    component={
                                        <Page title="Shreekalp">
                                            <Shreekalp />
                                        </Page>
                                    }
                                />
                            />

                            <Route
                                path="/more"
                                element=<ProtectedComponent
                                    component={
                                        <Page title="More">
                                            <More />
                                        </Page>
                                    }
                                />
                            />

                            <Route
                                path="/info"
                                element=<ProtectedComponent
                                    component={
                                        <Page title="Shreekalp">
                                            <Shreekalp />
                                        </Page>
                                    }
                                />
                            />
                        </Routes>
                    </Router>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    );
}

const LoadingScreen = () => {
    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default App;
